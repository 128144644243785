<app-simple-banner></app-simple-banner>
<div id="mb-menu-wrap" [ngClass]="{'mb_open': wrap_menu}" class="mobile-menu-open">
    <a *ngIf="!isHideAllRoute() && !isPurchaseOkRoute()" [class]="isCourseActiveRoute()" (click)="onNavMobile()" class="lf-e-6 medium"
        routerLink="cursos">{{'nav.courses' |
        translate}}<div class="line"></div></a>
    <a *ngIf="!isHideAllRoute() && !isPurchaseOkRoute()" routerLinkActive="active" (click)="onNavMobile()" class="lf-e-6 medium"
        routerLink="metodo-liceo">{{'nav.method' |
        translate}}<div class="line"></div></a>
    <a *ngIf="!isHideAllRoute() && !isPurchaseOkRoute()" routerLinkActive="active" (click)="onNavMobile()" class="lf-e-6 medium"
        routerLink="planes">{{'nav.plans' |
        translate}}<div class="line"></div></a>
    <a *ngIf="!isHideAllRoute() && !isPurchaseOkRoute()" routerLinkActive="active" (click)="onNavMobile()" href="https://liceodefarmacia.com/blog/"
        class="lf-e-6 medium">{{'nav.blog' |
        translate}}<div class="line"></div></a>
    <a *ngIf="!isHideAllRoute() && !isPurchaseOkRoute() && !hasSession()" [class]="isProfileActiveRoute()" (click)="onNavMobile()" class="lf-e-6 medium"
        routerLink="mi-perfil/iniciar-sesion">{{myProfileText}}
        <div class="line"></div>
    </a>
    <app-button *ngIf="!isHideAllRoute() && !isPurchaseOkRoute()" (click)="gotoFreeTest(true)" class="btn_preuba_gratis" routerLink="prueba-gratis/seleccion"
        [type]="2" text="{{'nav.free_trial' | translate}}">
    </app-button>
    <!-- ---------- on dashboard ---------- -->
    <!-- <a *ngIf="hasPlans && !isHideAllRoute()" class="lf-e-6 medium" (click)="onNavMobile('stats')" routerLink="aula/campus"
        routerLinkActive="active">{{'footer.t_estats' | translate}}<div class="line"></div></a>
    <a *ngIf="hasPlans && !isHideAllRoute()" class="lf-e-6 medium" (click)="onNavMobile('liceo-play')" routerLink="aula/liceo-play"
        routerLinkActive="active">{{'footer.t_liceo_play' | translate}}<div class="line">
        </div>
    </a>
    <a *ngIf="hasPlans && !isHideAllRoute()" class="lf-e-6 medium" (click)="onNavMobile('protocolos')"
        routerLink="aula/protocolos-de-consejo" routerLinkActive="active">{{'footer.t_protocolos' | translate}}<div
            class="line">
        </div></a>
    <div *ngIf="hasPlans && !isHideAllRoute()" class="pharma-info">
        <div class="info-data">
            <div class="pharma-data">
                <div *ngIf="groupName != ''" class="group">
                    <p class="lf-p-4 regular">Grupo</p>
                    <p class="neg-mg-top lf-subtitle bold name">{{groupName}}</p>
                </div>
                <p *ngIf="pharmacyName != ''" class="neg-mg-top lf-p-4 regular pharma-name">{{pharmacyName}}</p>
            </div>
            <div class="icon">
                <div *ngIf="pharmacyName != ''" class="img-background"><img src="../../../../assets/svg/liceo-icons/pharmacy-icon.svg" alt="">
                </div>
            </div>
        </div>
        <div class="sponsors" *ngIf="sponsors.length > 0">
            <div class="sponsor" *ngFor="let sponsor of sponsors">
                <img [src]="imgSponsorUrl+sponsor.img" alt="">
            </div>
        </div>
    </div> -->
    <!-- ---------- end on dashboard ---------- -->
</div>
<nav #desktopNav [class.glass-1]="scrolled" class="desktop-ui">
    <div class="logo">
        <a href="/"><img [src]="assetsEnvironment+'/img/nav/nav_logo.svg'" alt="" width="150"></a>
    </div>
    <!-- <div *ngIf="hasPlans && !isVisibleSelectSpaces && !idAlreadyHaveActivePlanRoute() && !isHideAllRoute()" class="sections">
        <a class="lf-p-4 semibold" routerLink="aula/campus" routerLinkActive="active">{{'footer.t_estats' |
            translate}}<div class="line"></div></a>
        <a class="lf-p-4 semibold" routerLink="aula/liceo-play" routerLinkActive="active">{{'footer.t_liceo_play' |
            translate}}<div class="line">
            </div>
        </a>
        <a class="lf-p-4 semibold" routerLink="aula/protocolos-de-consejo"
            routerLinkActive="active">{{'footer.t_protocolos' | translate}}<div class="line">
            </div></a>
    </div> -->
    <div class="sections" [ngClass]="{loged: (!isFreeTrialOrCartActiveRoute() && !isDashboardRoute()) && hasSession()}">
        <a [ngClass]="{hidden: isHideAllRoute() || isFreeTrialOrCartActiveRoute() || isPurchaseOkRoute() || idAlreadyHaveActivePlanRoute()}" [class]="isCourseActiveRoute()" class="lf-p-4 semibold"
            routerLink="cursos">{{'nav.courses' | translate}} <div class="line"></div></a>
        <a [ngClass]="{hidden: isHideAllRoute() || isFreeTrialOrCartActiveRoute() || isPurchaseOkRoute() || idAlreadyHaveActivePlanRoute()}" routerLinkActive="active" class="lf-p-4 semibold"
            routerLink="metodo-liceo">{{'nav.method' | translate}} <div class="line"></div></a>
        <a [ngClass]="{hidden: isHideAllRoute() || isFreeTrialOrCartActiveRoute() || isPurchaseOkRoute() || idAlreadyHaveActivePlanRoute()}" routerLinkActive="active" class="lf-p-4 semibold"
            routerLink="planes">{{'nav.plans' | translate}} <div class="line"></div></a>
        <a [ngClass]="{hidden: isHideAllRoute() || isFreeTrialOrCartActiveRoute() || isPurchaseOkRoute() || idAlreadyHaveActivePlanRoute()}" href="https://liceodefarmacia.com/blog/"
            routerLinkActive="active" class="lf-p-4 semibold">{{'nav.blog' |
            translate}} <div class="line"></div></a>
        <a [ngClass]="{hidden: isHideAllRoute() || isFreeTrialOrCartActiveRoute() || isPurchaseOkRoute() || idAlreadyHaveActivePlanRoute() || hasSession()}" [class]="isProfileActiveRoute()" class="lf-p-4 semibold"
            routerLink="mi-perfil/iniciar-sesion">{{myProfileText}} <div class="line"></div></a>
        <a  *ngIf="!isHideAllRoute() && !isPurchaseOkRoute() && !isFreeTrialOrCartActiveRoute() && !isPurchaseOkRoute() && !idAlreadyHaveActivePlanRoute()" (click)="gotoFreeTest(false)" [class]="isFreeTrialActiveRoute()" class="btn-1" routerLink="prueba-gratis/seleccion">
            {{'nav.free_trial' | translate}}
            <div class="line"></div>
        </a>
    </div>
</nav>
<div *ngIf="((isFreeTrialOrCartActiveRoute() || isDashboardRoute() || idAlreadyHaveActivePlanRoute()) && hasSession()) " class="selector">
    <app-dropdown-menu (optionSelected)="onClickDropDownOption($event)" [userName]="userName" [alignRight]="true" [options]="menuOptions"></app-dropdown-menu>
</div>
<div *ngIf="((!isFreeTrialOrCartActiveRoute() && !isDashboardRoute()) && hasSession()) " class="selector small">
    <app-dropdown-menu [isReducedDesign]="true" (optionSelected)="onClickDropDownOption($event)" [userName]="userName" [alignRight]="true" [options]="menuOptions"></app-dropdown-menu>
</div>
<nav [class.glass-1]="scrolled" id="menu_mb" [ngClass]="{'ui-nav-fixed': menu_mb}" class="mobile-ui">
    <div id="btn-hamburger" [ngClass]="{open: hamburger, hide: isFreeTrialOrPurchaseSelectionRoute() || isHideAllRoute()}" class="hamburger" (click)="toggleClass()">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
    <a href="/"><img [src]="assetsEnvironment+'/img/nav/nav_logo.svg'" alt="" width="110" [ngClass]="{'hamburger-hide': isFreeTrialOrPurchaseSelectionRoute() || isHideAllRoute()}" class="logo"></a>
</nav>
